html {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div[name='ckEditorView'] .image-style-align-right {
  display: flex;
  min-width: 100%;
  align-items: flex-end !important;
}

div[name='ckEditorView'] .image-style-align-left {
  display: flex;
  min-width: 100%;
  align-items: flex-start !important;
}

div[name='ckEditorView'] .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  margin-left: 0;
}
div[name='ckEditorView'] .image_resized img {
  width: inherit;
}

div[name='ckEditorView'] table li {
  text-align: left;
}
